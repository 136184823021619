import './i18n';
import '../components/layout.css';

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ParallaxProvider } from 'react-scroll-parallax';
import { Script } from "gatsby";

import {
  HeroBannerSection,
  PropertiesSection,
  LoremSection,
  FractionalSection,
  BenefitsSection,
  HowSection,
  WhyAncanaSection
} from "../components/sectionsV3";
import ContactSection from "../components/sectionsV3/ContactSection";
import FaqSection from "../components/sectionsV3/FaqSection";
import Footer from "../components/sectionsV3/Footer";
import GallerySection from "../components/sectionsV3/GallerySection";
import MentionsSection from "../components/sectionsV3/MentionsSection";
import NavBar from "../components/sectionsV3/NavBar";
import { scrollToSection } from "../misc/utils";
import { Helmet } from "react-helmet";
import { adminApi } from "../api/ancanaAPI";
import ContactModal from "../components/ContactModal";
import useObserveRef from "../hooks/useObserveRef";


const Container = styled.div`
  overflow-x: hidden;
  // background-color: #FDFBF7;

  .ancana-logo {
    width: 138px;
  }
`;

const mockedListings = [];

const V2Page = ({ pageContext }) => {
  const [listings, setListings] = useState(mockedListings);
  const containerRef = useRef(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [alreadyPopedup, setAlreadyPopedup] = useState(false);
  const alreadyPopedupRef = useRef(alreadyPopedup);

  const openPopup = () => {
    if(!alreadyPopedupRef.current) {
      setPopupVisible(true);
      // alreadyPopedupRef.current = true;
      setAlreadyPopedup(true);
    }
  }

  const { observedRef } = useObserveRef(alreadyPopedup, openPopup);

  const scrollTo = (name, offset = 0, cancelPopup = true) => {
    if (cancelPopup) setAlreadyPopedup(true);
    scrollToSection(name, containerRef, offset);
  }

  useEffect(() => {
    adminApi.get('listings/previews/?page_size=8&published=true&location_slug=puerto-escondido').then(res => {
      setListings(res.data.results)
    });
    const timeout = setTimeout(() => openPopup(), 15000);
    return () => {
      clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    alreadyPopedupRef.current = alreadyPopedup;
  }, [alreadyPopedup]);

  return (
    <ParallaxProvider>
      <Container ref={containerRef}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Ancana - Puerto Escondido</title>
          <meta name={`robots`} content={`noindex, nofollow`} />
        </Helmet>
        <NavBar scrollTo={scrollTo} />

        <div id="hero">
          <HeroBannerSection scrollTo={scrollTo} />
        </div>
        <div id="property">
          <PropertiesSection listings={listings} scrollTo={scrollTo} />
        </div>
        <LoremSection />
        <div id="fractional">
          <FractionalSection />
        </div>
          <BenefitsSection />
        <div ref={observedRef}>
         <HowSection />
        </div>
        <WhyAncanaSection />
        <div id="contact">
          <ContactSection />
        </div>

        <GallerySection houses={listings} />
        <MentionsSection />

        <FaqSection />

        <Footer scrollTo={(name) => scrollToSection(name, containerRef)} />
      </Container>

      <ContactModal
        visible={popupVisible}
        handleClose={() => setPopupVisible(false)}
      />
    </ParallaxProvider>
  );
}

export default V2Page;
