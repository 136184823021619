import React from "react";
import styled from "styled-components";
import H from "../text/H";
import P from "../text/P";
import Ancana from '../../images/icons/ancana-whitish.inline.svg';
import Check from '../../images/icons/ccheck.inline.svg';
import Cross from '../../images/icons/ccross.inline.svg';
import { bravoPalette, breakPoints, corporatePalette } from "../../misc/consts";
import B from "../text/B";

const Container = styled.div`
  .tables {
    flex-direction: column;
    ${breakPoints.desktop} {
      flex-direction: row;
    }
  }
`;

function WhyAncanaSection() {
  return (
    <Container className="white-bg">
      <H className="fs-5 center">¿Por qué comprar con Ancana?</H>
      <P className="fs-3 center-self mb-xl">Ancana, mucho más que solo ser dueño</P>

      <div className="tables marginal gap-lg tablet-and-up" style={{ marginTop: 0 }}>
        <div className="shadow-3 white-bg corp-shadow-sm flx1">
          <div className="pd-lg center" style={{ backgroundColor: corporatePalette.blue }}>
            <Ancana />
          </div>
          <div className="pd-lg">
            <B className="fs-4 fw-underline mb-sm">Precio</B>
            <H className="fs-4-5 mb-md" color={corporatePalette.sand}>$5,000,000.00</H>
            <B className="fs-4 fw-underline mb-sm">Mantenimiento</B>
            <H className="fs-4-5 mb-md" color={corporatePalette.sand}>$15,000 MXN</H>
            <B className="fs-4 fw-underline mb-lg">Beneficios</B>
            <div className="h-stack-strict mb-lg">
              <Check className="mr-md" />
              <P>
                Eres dueño
              </P>
            </div>
            <div className="h-stack-strict mb-lg">
              <Check className="mr-md" />
              <P>
                <b>Acabados de lujo</b>
              </P>
            </div>
            <div className="h-stack-strict mb-lg">
              <Check className="mr-md" />
              <P>
                <b>Ubicación exclusiva</b>
              </P>
            </div>
            <div className="h-stack-strict mb-lg">
              <Check className="mr-md" />
              <P>
                Amenidades de alta gama
              </P>
            </div>
            <div className="h-stack-strict">
              <Check className="mr-md" />
              <P>
                Administración de la propiedad
              </P>
            </div>
          </div>
        </div>
        <div className="shadow-3 white-bg flx1">
          <div className="pd-lg center" style={{ backgroundColor: corporatePalette.blue }}>
            <P className="fs-4 fw-200" color={corporatePalette.almostWhite}>Propiedades completas</P>
          </div>
          <div className="pd-lg">
            <B className="fs-4 fw-underline mb-sm">Precio</B>
            <H className="fs-4-5 mb-md" color={corporatePalette.sand}>$20,000,000.00</H>
            <B className="fs-4 fw-underline mb-sm">Mantenimiento</B>
            <H className="fs-4-5 mb-md" color={corporatePalette.sand}>$40,000 MXN</H>
            <B className="fs-4 fw-underline mb-lg">Beneficios</B>
            <div className="h-stack-strict mb-lg">
              <Check className="mr-md" />
              <P>
                Eres dueño
              </P>
            </div>
            <div className="h-stack-strict mb-lg">
              <Check className="mr-md" />
              <P>
                <b>Acabados de lujo</b>
              </P>
            </div>
            <div className="h-stack-strict mb-lg">
              <Check className="mr-md" />
              <P>
                <b>Ubicación exclusiva</b>
              </P>
            </div>
            <div className="h-stack-strict mb-lg">
              <Cross className="mr-md" />
              <P>
                Amenidades de alta gama
              </P>
            </div>
            <div className="h-stack-strict">
              <Cross className="mr-md" />
              <P>
                Administración de la propiedad
              </P>
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-only full-width white-bg" style={{ overflow: 'visible', zIndex: 3 }}>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.blue }}>
          <div className="flx1"></div>
          <div className="flx1 fw-400 center-text" style={{ color: bravoPalette.almostWhite }}>ANCANA</div>
          <div className="flx1 fw-400 center-text" style={{ color: bravoPalette.almostWhite }}>Propiedades completas</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center">
          <div className="flx1 fw-600">Precio</div>
          <div className="flx1 fw-600 center" style={{ color: bravoPalette.dryLeaf }}>$15,000 MXN</div>
          <div className="flx1 fw-600 center" style={{ color: bravoPalette.dryLeaf }}>$40,000 MXN</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.almostLeaf }}>
          <div className="flx1 fw-600">Mantinimiento</div>
          <div className="flx1 fw-600 center" style={{ color: bravoPalette.dryLeaf }}>$15,000 MXN</div>
          <div className="flx1 fw-600 center" style={{ color: bravoPalette.dryLeaf }}>$40,000 MXN</div>
        </div>
        <div className="pd-sm pt-xs pb-xs h-stack-strict gap-xs center">
          <div className="flx1 fw-600">Beneficios:</div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.almostLeaf }}>
          <div className="flx1">Eres dueño</div>
          <div className="flx1 center"><Check /></div>
          <div className="flx1 center"><Check /></div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center">
          <div className="flx1 fw-600">Acabados de lugo</div>
          <div className="flx1 center"><Check /></div>
          <div className="flx1 center"><Cross /></div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.almostLeaf }}>
          <div className="flx1 fw-600">Ubicación exclusiva</div>
          <div className="flx1 center"><Check /></div>
          <div className="flx1 center"><Cross /></div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center">
          <div className="flx1">Amenidades de alta gama</div>
          <div className="flx1 center"><Check /></div>
          <div className="flx1 center"><Cross /></div>
        </div>
        <div className="pd-sm h-stack-strict gap-xs center" style={{ backgroundColor: bravoPalette.almostLeaf }}>
          <div className="flx1">Administración de la propiedad</div>
          <div className="flx1 center"><Check /></div>
          <div className="flx1 center"><Cross /></div>
        </div>
        <div className="full-width mb-xl" />
      </div>

      <br/><br/><br/>
    </Container>
  )
}

export default WhyAncanaSection;
