import React, { useEffect, useState } from "react";
import styled from 'styled-components';

import ContactForm from "./ContactForm";
import Ancana from '../images/icons/ancana-newgold.inline.svg';
import X from '../images/icons/x.inline.svg';
import { ancanaPalette, bravoPalette, breakPoints } from "../misc/consts";
import Button from "./Button";


const Container = styled.div`
  position: fixed;
  z-index: ${({ visible }) => visible ? 15 : -1};
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, .66);
  backdrop-filter: blur(1px);
  opacity: ${({ visible }) => visible ? 1 : 0};
  transition: opacity 1s, z-index 1s;

  .content {
    margin: auto;
    width: 380px;
    padding: 24px;

    ${breakPoints.tablet} {
      width: 430px;
      padding: 26px;
    }

    ${breakPoints.desktop} {
      width: 540px;
      padding: 30px;
    }

    background: rgb(240, 242, 247);
    background: linear-gradient(0deg, rgba(240, 242, 247, 1) 0%, rgba(255, 255, 255, 1) 90%);
    border-radius: 16px;
    box-shadow: 0 20px 36px rgba(59, 75, 118, .26);
    text-align: center;
    position: relative;

    .close-x {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }

    .a-icon {
      align-self: center;
      margin-bottom: 16px;

      ${breakPoints.desktop} {
        width: 200px;
      }
    }

    .contact-form {
      padding: 0;
      background-color: transparent;

      margin: 0 auto;
      width: 245px;

      ${breakPoints} {
        width: 260px;
      }
      ${breakPoints} {
        width: 310px;
      }
      .input {
        border-color: #CCCCCC;
      }
      .label {
        color: #707070;
        display: none;
      }
    }

    .title {
      font-size: 16px;

      ${breakPoints.desktop} {
        font-size: 18px;
      }

      color: #707070;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .subtitle {
      font-size: 11px;
      font-weight: 200;
      color: #707070;

      ${breakPoints.desktop} {
        font-size: 14px;
      }
    }

    .close-txt {
      margin: auto;
      color: #707070;
      font-weight: 500;
      letter-spacing: 1px;
      cursor: pointer;
      font-size: 14px;
      ${breakPoints.tablet} {
        font-size: 16px;
      }
      :hover {
        text-decoration: underline
      }
    }
  }
`;

function ContactModal({ visible, handleClose }) {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [visible]);

  return (
    <Container
      visible={visible}
      // onClick={handleClose}
    >
      <div className="content" onClick={(e) => e.stopPropagation()}>
        <X className="close-x" onClick={handleClose} />
        <Ancana className="a-icon"  />
        <div className="title">
          Encontremos juntos la casa
          vacacional de tus sueños
        </div>
        <div className="subtitle">
          Uno de nuestros asesores expertos se
          pondrá en contacto contigo para brindarte
          información detallada sobre las propiedades
          y resolverá todas tus dudas.
        </div>

        <ContactForm
          className="contact-form mb-lg"
          fields={['name', 'email', 'phone']}
          cta="Enviar datos"
        />

        <div className="close-txt" onClick={handleClose}>
          CERRAR
        </div>
      </div>
    </Container>
  )
}

export default ContactModal;
