import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import H from "../text/H";
import P from "../text/P";
import Button from "../Button";
import AncanaWhitish from '../../images/icons/ancana-whitish.inline.svg';
import { Parallax } from 'react-scroll-parallax';

import { bravoPalette, breakPoints, corporatePalette } from "../../misc/consts";

const Container = styled.div`
  height: calc(100vh - 75px);
  position: relative;

  ${breakPoints.tablet} {
    height: 100vh;
  }

  .hhl {
    color: ${corporatePalette.sand};
  }

  .hero-img {
    img {
      filter: blur(.25px);
    }
    height: 100vh;
  }

  .logo {
    margin-bottom: 24px;
  }

  .centered {
    flex: 1;
    justify-content: center;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const FloatingBand = styled.div`
  background: rgba(0,0,0,.75);
  position: absolute;
  top: 10%;
  left: 0;
  height: 80%;
  width: 75%;
  max-width: 260px;
  padding: 24px 24px;

  ${breakPoints.tablet} {
    max-height: 540px;
    top: 0;
    left: 5%;
  }
  ${breakPoints.desktop} {
    max-height: 1240px;
    top: 0;
    left: 7%;
    height: 100%;
    width: 37%;
    max-width: 420px;
  }
  ${breakPoints.largeDesktop} {
    max-height: 1240px;
    top: 0;
    left: 10%;
    height: 100%;
    width: 30%;
    max-width: 420px;
  }
`;

function HeroBannerSection({ scrollTo }) {
  return (
    <Container>
      <Parallax speed={-15} style={{zIndex: -1}}>
        <StaticImage
          src="../../images/properties/palmar-2.jpg"
          alt="ancana-vega-del-bosque"
          layout="fullWidth"
          quality={100}
          className="hero-img"
        />
        <Overlay />
      </Parallax>
      <FloatingBand>
        <AncanaWhitish className="logo" />

        <div className="centered">
          <H className="mb-sm fs-9" color={bravoPalette.almostWhite}>
            La forma <span className="hhl">inteligente</span> de comprar <span className="hhl">casa en Puerto Escondido</span>
          </H>
          <P className="mb-lg fw-200" color="white">
            Conoce la manera más astuta de disfrutar de una lujosa casa en Puerto Escondido
          </P>
          <Button
            color="#FFFFFF"
            variant="outlined"
            onClick={() => scrollTo('contact')}
          >
            Contactar asesor
          </Button>
        </div>
      </FloatingBand>
    </Container>
  )
}

export default HeroBannerSection;
